<template>
  <v-main>
    <v-container>
    <v-tabs centered>
      <v-tab>Like</v-tab>
      <v-tab>Time</v-tab>
    </v-tabs>
    <v-card>
    <v-toolbar>

      <v-btn icon>
        <v-icon>mdi-ethereum</v-icon>
      </v-btn>

      <v-toolbar-title>{{ $route.query.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon v-on:click="addPost($route.params.tag)">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list three-line>
      <template v-for="post in posts">

        <v-list-item 
          :key="post.title"
          v-on:click="goPost(post.address)">
          <!-- <v-list-item-avatar>
            <v-img :src="item.avatar"></v-img>
          </v-list-item-avatar> -->

          <v-list-item-content>
            <v-list-item-title v-html="post.title"></v-list-item-title>
            <!-- <v-list-item-subtitle v-html="post.subtitle"></v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

      </template>
    </v-list>
  </v-card>

    </v-container>
  </v-main>
</template>

<script>

import { getPosts, getPostName } from '@/api/ethereum'

export default {
  name: 'Articles',
  props: {
    msg: String
  },
  data() {
    return {
      posts: [],
    }
  },
  mounted() {
    console.log('this.$route.params.tag', this.$route.params.tag)
    this.reload(this.$route.params.tag)
  },
  methods: {
    goPost: function (address) {
      this.$router.push({ name: 'Article', params: { article: address } })
    },
    addPost: function (address) {
      this.$router.push({ name: 'ArticleAdd', params: { tag: address } })
    },
    reload: function (address) {
      console.log('address', address)
      var that = this
      getPosts(address).then(async (values) => {
        console.log('posts', values)
        for(var i=0; i<values.length; i++) {
          const postAddress = values[i]
          getPostName(postAddress).then((postName) => {
            console.log('posts', postName)
            that.$data.posts.push({address: postAddress, title: postName})
          })
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
