<template>
  <v-main>
    <v-container>
      <form>
      <v-textarea
        v-model="content"
        label="Content"
        required
        ></v-textarea>

      <v-btn
        :loading="loading"
        :disabled="loading"
        style="width: 100%;"
        @click="submit"
      >
        Submit
      </v-btn>
    </form>
  
    </v-container>
  </v-main>
</template>

<script>
import { postComment } from '@/api/ethereum'
const Swal = require('sweetalert2')

export default {
  name: 'PostComment',
  props: {
    msg: String
  },
  data() {
    return {
      title: '',
      content: '',
      loading: false,
    }
  },
  methods: {
    submit: async function () {
      var tx = await postComment(this.$route.params.article, this.$data.content)
      this.$data.loading = true;
      await tx.wait();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Post Successed!',
        showConfirmButton: false,
        timer: 2000
      })
      this.$router.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
