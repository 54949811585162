<template>
  <v-main>
    <v-container>
    <h1 style="text-align: center;
    padding: 2rem;">{{ title }}</h1>
    <h5 style="padding: 1rem">{{ date }}</h5>
    <v-md-preview :text="content"></v-md-preview>
    <h5 style="padding: 1rem">{{ author }}</h5>
  
    <v-divider style="margin-top: 3rem;"></v-divider>
    <v-toolbar dense elevation="0">

      <v-toolbar-title>Comments</v-toolbar-title>

      <v-spacer></v-spacer>
      <span>{{ likes }}</span>
      <v-btn 
        icon
        :loading="liking"
        :disabled="liking" 
        @click="like">
        <v-icon>mdi-thumb-up</v-icon>
      </v-btn>

      <span>{{ commentCount }}</span>

      <v-btn 
        icon 
        v-on:click="addComment($route.params.article)">
        <v-icon>mdi-chat</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list three-line>
      <template v-for="comment in comments">

        <v-list-item 
          :key="comment.address">
          <!-- <v-list-item-avatar>
            <v-img :src="item.avatar"></v-img>
          </v-list-item-avatar> -->

          <v-list-item-content>
            <!-- <v-list-item-title v-html="post.title"></v-list-item-title> -->
            <v-list-item-subtitle v-html="comment.content"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </template>
    </v-list>

    </v-container>
  </v-main>
</template>

<script>
import { getPostName, getPostContent, getComments, getCommentContent, getPostAuthor, getPostDate, likeArticle, getLikes, getCommentCount } from '@/api/ethereum'

import Vue from 'vue';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

Vue.use(VMdPreview);

export default {
  name: 'Article',
  props: {
    msg: String
  },
  data() {
    return {
      liking: false,
      likes: 0,
      commentCount: 0,
      title: '',
      date: '',
      content: '',
      author: '',
      comments: [],
    }
  },
  mounted() {
    console.log('this.$route.params.article', this.$route.params.article)
    this.updateTitle(this.$route.params.article)
    this.updateDate(this.$route.params.article)
    this.updateContent(this.$route.params.article)
    this.updateAuthor(this.$route.params.article)
    this.updateComments(this.$route.params.article)
    this.updateLike(this.$route.params.article)
    this.updateCommentCount(this.$route.params.article)

  },
  methods: {
    like: async function () {
      console.log(this.$route.params.article)
      var tx = await likeArticle(this.$route.params.article)
      this.$data.liking = true;
      await tx.wait();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Like successed!',
        showConfirmButton: false,
        timer: 2000
      })
      this.$data.liking = false;
      this.updateLike(this.$route.params.article)
    },
    addComment: function (article) {
      this.$router.push({ name: 'CommentAdd', params: { article: article } })
    },
    updateCommentCount: function (address) {
      var that = this
      getCommentCount(address).then(async (value) => {
        that.$data.commentCount = value
      })
    },
    updateLike: function (address) {
      var that = this
      getLikes(address).then(async (value) => {
        that.$data.likes = value
      })
    },
    updateTitle: function (address) {
      var that = this
      getPostName(address).then(async (value) => {
        that.$data.title = value
      })
    },
    updateDate: function (address) {
      var that = this
      getPostDate(address).then(async (value) => {
        that.$data.date = new Date(parseInt(value) * 1000);
      })
    },
    updateContent: function (address) {
      var that = this
      getPostContent(address).then(async (value) => {
        that.$data.content = value
      })
    },
    updateAuthor: function (address) {
      var that = this
      getPostAuthor(address).then(async (value) => {
        that.$data.author = value
      })
    },
    updateComments: function (address) {
      var that = this
      getComments(address).then(async (values) => {
        console.log(values)
        var results = []
        for(var i=0; i<values.length; i++) {
          const addr = values[i]
          const index = i
          getCommentContent(addr).then((comment) => {
            console.log(index, comment, addr)
            results.push({"content": comment, "index": index, "address": addr})
          })
        }
        that.$data.comments = results
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
