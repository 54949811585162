<template>
  <v-main>
    <v-container>
      <form>
      <v-text-field
        v-model="title"
        label="Title"
        required
      ></v-text-field>

      <v-md-editor v-model="content" style="min-height: 500px"></v-md-editor>

      <v-btn
        :loading="loading"
        :disabled="loading"
        style="width: 100%; margin-top: 2rem"
        @click="submit"
      >
        Submit
      </v-btn>
    </form>
  
    </v-container>
  </v-main>
</template>

<script>
import { postArticle } from '@/api/ethereum'
const Swal = require('sweetalert2')
import Vue from 'vue';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

Vue.use(VMdEditor);


export default {
  name: 'Article',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      title: '',
      content: '',
      loading: false,
    }
  },
  methods: {
    submit: async function () {
      var tx = await postArticle(this.$route.params.tag, this.$data.title, this.$data.content)
      this.$data.loading = true;
      await tx.wait();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Post Successed!',
        showConfirmButton: false,
        timer: 2000
      })
      this.$router.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
