import FREEZE_ABI from './freeze_abi.json'
import POST_ABI from './post_abi.json'
import TAG_ABI from './tag_abi.json'

const { ethers } = require("ethers");

// ETH TEST
// const FREEZE_ADDRESS = '0x64d9Cde364Cb03A967CB0BC7de3B4041Fb360f37'
// BSC TEST
const FREEZE_ADDRESS = '0x02C5FF36BaDE8a10fB2468816Ae66546207F2Cd6'


var provider

function providerCheck() {
  if (provider != undefined) return
  if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum)
  } else if (window.web3) {
    provider = new ethers.providers.Web3Provider(window.web3.currentProvider)
  }
  provider.send("eth_requestAccounts", []);
}

export function getTags() {
  providerCheck()
  const contract = new ethers.Contract(FREEZE_ADDRESS, FREEZE_ABI, provider);
  return contract.getListByTime(0, 100)
}

export function getTagName(address) {
  providerCheck()
  const contract = new ethers.Contract(FREEZE_ADDRESS, FREEZE_ABI, provider);
  return contract.getTag(address)
}

export function getPosts(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.getListByTime(0, 100)
}

export function getPostName(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.title()
}

export function getPostContent(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.content()
}

export function getPostAuthor(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.owner()
}

export function getPostDate(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.date()
}

export function getComments(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.getListByTime(0, 100)
}

export function getCommentContent(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.content()
}

export function getCommentCount(address) {
  providerCheck()
  const contract = new ethers.Contract(address, POST_ABI, provider);
  return contract.length()
}

export function postArticle(tag, title, content) {
  providerCheck()
  const contract = new ethers.Contract(tag, TAG_ABI, provider.getSigner());
  return contract.createPost(title, content)
}

export function likeArticle(article) {
  providerCheck()
  const contract = new ethers.Contract(article, POST_ABI, provider.getSigner());
  return contract.like()
}

export function getLikes(article) {
  providerCheck()
  const contract = new ethers.Contract(article, POST_ABI, provider);
  return contract.likes()
}

export function postComment(article, content) {
  providerCheck()
  const contract = new ethers.Contract(article, POST_ABI, provider.getSigner());
  return contract.createComment(content)
}

export function createTag(tag) {
  providerCheck()
  const contract = new ethers.Contract(FREEZE_ADDRESS, FREEZE_ABI, provider.getSigner());
  return contract.createTag(tag)
}