<template>
  <v-container fluid>
    <v-combobox
      v-model="searchModel"
      :items="searchItems"
      hide-selected
      label="Search some tags"
      persistent-hint
      @keyup.enter="createTag()"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>

    <wordcloud
      :data="tags"
      :wordClick="wordClickHandler"
      nameKey="name"
      valueKey="value">
      </wordcloud>
  </v-container>
</template>

<script>
import wordcloud from 'vue-wordcloud'
import { getTags, getTagName, createTag } from '@/api/ethereum'
const Swal = require('sweetalert2')

export default {
  name: 'Tags',
  components: {
    wordcloud
  },
  props: {
    msg: String
  },
  data() {
    return {
      tags: [],
      tag2addr: {},
      
      searchItems: [],
      searchModel: null,
      search: null,
    }
  },
  watch: {
    searchModel (val) {
    },
  },
  mounted() {
    var that = this
    getTags().then(async (values) => {
      var results = []
      for(var i=0; i<values.length; i++) {
        const addr = values[i]
        const index = i
        getTagName(values[i]).then((tagName) => {
          console.log(index, tagName, addr)
          results.push({"name": tagName, "value": index, "address": addr})
          that.$data.tag2addr[tagName] = addr
          if (that.$data.searchItems.indexOf(tagName) < 0) {
            that.$data.searchItems.push(tagName)
          }
          if (that.$data.searchModel != null && that.$data.searchModel.length <= 0) {
            that.$data.searchModel.push(tagName)
          }
        })
      }
      that.$data.tags = results
    })
  },
  methods: {
    createTag: function () {
      console.log(this.$data.searchModel)
      if (this.$data.tag2addr[this.$data.searchModel] == undefined) {
        Swal.fire({
          title: 'Do you want to create?',
          text: 'Tag: ' + this.$data.searchModel,
          showCancelButton: true,
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed == false) return
          this.$router.push({ name: 'TagAdd', query: { tag: this.$data.searchModel } })
        })
      } else {
        this.$router.push({ name: 'Articles', params: { tag: this.$data.tag2addr[this.$data.searchModel] }, query: { name: this.$data.searchModel } })
      }
    },
    wordClickHandler: function (name, value, vm) {
      console.log('wordClickHandler', name, value, vm, this.$data.tag2addr[name]);
      this.$router.push({ name: 'Articles', params: { tag: this.$data.tag2addr[name] }, query: { name: name } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
