import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home'
import Tags from '../components/Tags'
import TagAdd from '../components/TagAdd'
import Articles from '../components/Articles'
import Article from '../components/Article'
import ArticleAdd from '../components/ArticleAdd'
import CommentAdd from '../components/CommentAdd'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        { 
            path: '/', 
            component: Home,
            children: [
                {
                  path: '/',
                  name: 'Tags',
                  component: Tags
                },
                {
                  path: '/articles/:tag',
                  name: 'Articles',
                  component: Articles
                },
                {
                  path: '/article/:article',
                  name: 'Article',
                  component: Article
                },
                {
                  path: '/articleadd/:tag',
                  name: 'ArticleAdd',
                  component: ArticleAdd
                },
                {
                  path: '/commentadd/:article',
                  name: 'CommentAdd',
                  component: CommentAdd
                },
                {
                  path: '/tagadd/',
                  name: 'TagAdd',
                  component: TagAdd
                }
              ]
        }
    ]
})
